<template>
  <div class="MemberNavItem">
      <div class="title">
          <slot name="title"></slot>
      </div>
      <div class="list">
          <div class="item" v-for="item in listItem" :key="item.name" @click="go(item.path)" :class="{active:$route.path == item.path}">
            {{item.name}}
          </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "MemberNavItem",
  data() {
    return {};
  },
  created() {},
  methods:{
      go(path){
          this.$router.push({
              path
          })
      }
  },

  props:{
      listItem:{
          type:Array,
          default:[]
      }
  }
};
</script>
<style lang='scss' scoped>

.MemberNavItem{
    padding:0.875rem;
    background-color: white;
    border-bottom:1px solid var(--border-color-default);
    .title{
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.5;
        color:var(--text-color-title);
    }
    .list{
        .item{
           
            color:var(--text-color-default);
            font-size: 0.75rem;
            line-height: 2.5;
            cursor: pointer;
            &:hover{
                color:var(--red-color);
            }
        }
        .active{
            color:var(--red-color); 
        }
    }
}

</style>