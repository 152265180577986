<template>
  <div class="member">
    <div class="container">
      <my-breadcrumb>
        <my-breadcrumb-item to="/">{{$t('Goods.Pages.Home')}}</my-breadcrumb-item>
        <my-breadcrumb-item to="/member/index">{{$t("User.Account.Pages.MyAccount")}}</my-breadcrumb-item>
        <my-breadcrumb-item :to="'/member/'+setName" :isDisabled="true" :active="true">{{setName}}</my-breadcrumb-item>
      </my-breadcrumb>
      <div class="main">
        <div class="left">
          <member-left></member-left>
        </div>
        <div class="right">
          <member-right></member-right>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MemberLeft, MemberRight } from "./child";
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";

export default {
  name: "member",
  data() {
    return {};
  },
  created() {
  
  },
  computed:{
    setName(){
      let name = this.$route.path
      let str = name.replace('/member/','')

      switch(str){
        case 'index' : return this.$t("User.Orders.Crumbs.Home");
        case 'orders' : return this.$t("User.Orders.Crumbs.Orders");
        case 'balance' : return this.$t("User.Orders.Crumbs.MyBalance");
        case 'favorite' : return this.$t("User.Orders.Crumbs.MyFav");
        case 'point' : return this.$t("User.Orders.Crumbs.MyPoint");
        case 'receiver' : return this.$t("User.Orders.Crumbs.MyReceiver");
        case 'info' : return this.$t("User.Orders.Crumbs.MyInfo");
        case 'coupon' : return this.$t("User.Orders.Crumbs.MyCoupon");
      }
    }
  },
  methods: {},
  components: {
    MemberLeft,
    MemberRight,
    MyBreadcrumb,
    MyBreadcrumbItem,
  },
};
</script>
<style lang='scss' scoped>
.member {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--bg-color-hint);
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.5rem;
    .left,
    .right {
      width: 100%;
      box-sizing: border-box;
    }
    .left {
      max-width: 15%;
      padding-right: 0.5rem;
    }
    .right {
      max-width: 85%;
      padding-left: 0.5rem;
    }
  }
}
</style>