<template>
  <div class="MemberLeft">
    <member-nav-item
      v-for="item in nav"
      :key="item.title"
      :listItem="item.listItem"
    >
      <template v-slot:title>
        {{ item.title }}
      </template>
    </member-nav-item>
  </div>
</template>
<script>
import MemberNavItem from "./memberLeftChild/MemberNavItem";
export default {
  name: "MemberLeft",
  data() {
    return {
      nav: [
        {
          title:this.$t("User.Account.Pages.OrderCenter"),
          listItem: [
            {
              name: this.$t("User.Orders.Crumbs.Orders"),
              path: "/member/orders",
            },
          ],
        },
        // {
        //   title:this.$t("User.Account.Pages.MyWallet"),
        //   listItem: [
        //     {
        //       name: this.$t("User.Orders.Crumbs.MyCoupon"),
        //       path: "/member/coupon",
        //     },
        //     {
        //       name: this.$t("User.Orders.Crumbs.MyPoint"),
        //       path: "/member/point",
        //     },
        //     {
        //       name: this.$t("User.Orders.Crumbs.MyBalance"),
        //       path: "/member/balance",
        //     },
        //   ],
        // },
        {
          title:this.$t("User.Account.Pages.MyInterest"),
          listItem: [
            {
              name: this.$t("User.Orders.Crumbs.MyFav"),
              path: "/member/favorite",
            },
          ],
        },
        {
          title:this.$t("User.Account.Pages.AccountSetting"),
          listItem: [
            {
              name: this.$t("User.Orders.Crumbs.MyReceiver"),
              path: "/member/receiver",
            },
            {
              name: this.$t("User.Orders.Crumbs.MyInfo"),
              path: "/member/info",
            },
          ],
        },
      ],
    };
  },

  created() {},
  components: {
    MemberNavItem,
  },
};
</script>
<style lang='scss' scoped>
</style>