<template>
  <div class="MemberRight">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "MemberRight",
  data() {
    return {};
  },
  created() {},
  props: {
    memberData: {
      type: Object,
    },
    orderList: {
      type: Object,
    },
  },
};
</script>
<style lang='scss' scoped>

</style>